import React, { useState, useEffect } from 'react';
import './navbar.css';
import { FaHome } from 'react-icons/fa';
import { IoMdContact } from 'react-icons/io';
import { TiMessages } from 'react-icons/ti';
import { IoCodeWorking } from 'react-icons/io5';
import { AiOutlineFundProjectionScreen, AiFillProject } from 'react-icons/ai';

const Navbar = () => {
  const [activeNav, setActiveNav] = useState('#');

  const handleNavClick = (navId) => {
    setActiveNav(navId);
  };

  useEffect(() => {
    const sections = ['#home', '#about', '#experience', '#services', '#contact'];

    const handleIntersection = (entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          setActiveNav(`#${entry.target.id}`);
        }
      });
    };

    const observer = new IntersectionObserver(handleIntersection, {
      rootMargin: '-50% 0% -50%', // Adjust the root margin as needed
    });

    sections.forEach((section) => {
      const target = document.querySelector(section);
      if (target) {
        observer.observe(target);
      }
    });

    return () => {
      observer.disconnect();
    };
  }, []);

  return (
    <nav>
      <a href="#home" onClick={() => handleNavClick('#home')} className={activeNav === '#home' ? 'active' : ''}>
        <FaHome />
      </a>
      <a href="#about" onClick={() => handleNavClick('#about')} className={activeNav === '#about' ? 'active' : ''}>
        <IoMdContact />
      </a>
      <a href="#experience" onClick={() => handleNavClick('#experience')} className={activeNav === '#experience' ? 'active' : ''}>
        <AiOutlineFundProjectionScreen />
      </a>
      <a href="#services" onClick={() => handleNavClick('#services')} className={activeNav === '#services' ? 'active' : ''}>
        <IoCodeWorking />
      </a>
      <a href="#portfolio" onClick={() => handleNavClick('#portfolio')} className={activeNav === '#portfolio' ? 'active' : ''}>
        <AiFillProject/>
      </a>
      <a href="#contact" onClick={() => handleNavClick('#contact')} className={activeNav === '#contact' ? 'active' : ''}>
        <TiMessages />
      </a>
    </nav>
  );
};

export default Navbar;
