import React from 'react';
import './services.css';
import { BsFillFileEarmarkCheckFill } from 'react-icons/bs';

const Services = () => {
  return (
    <section id='services'>
      <h5>What I Offer</h5>
      <h2>Services</h2>

      <div className='container services__container'>
        <article className="service">
          <div className="service__head">
            <h3>Static Web Pages</h3>
          </div>

          <ul className='service__list'>

            <li>
              <div> <BsFillFileEarmarkCheckFill className='service__list-icon'/> </div>
              <p>Simplicity: Cost-effective and straightforward to maintain.</p>
            </li>

            <li>
              <div> <BsFillFileEarmarkCheckFill className='service__list-icon'/> </div>
              <p>Content Integration: Text, images, and media seamlessly added.</p>
            </li>

            <li>
              <div> <BsFillFileEarmarkCheckFill className='service__list-icon'/> </div>
              <p>Low Maintenance: No need for regular updates.</p>
            </li>

          </ul>

          <div id='examples'>Example: This page</div> {/* Moved this outside the <ul> */}
        </article>

        {/* START OF Dynamic Web Pages  */}
        <article className="service">
          <div className="service__head">
            <h3>Dynamic Web Pages</h3>
          </div>

          <ul className='service__list'>

            <li>
              <div> <BsFillFileEarmarkCheckFill className='service__list-icon'/> </div>
              <p>Responsive: Perfect viewing on any device.</p>
            </li>

            <li>
              <div> <BsFillFileEarmarkCheckFill className='service__list-icon'/> </div>
              <p>User Interaction: Create interactive forms for user registration, login, and more.</p>
            </li>

            <li>
              <div> <BsFillFileEarmarkCheckFill className='service__list-icon'/> </div>
              <p>Dynamic Visuals: Slideshows, animations, and interactive elements.</p>
            </li>

          </ul>
        </article>

        {/* START OF FULLSTACK */}
        <article className="service">
          <div className="service__head">
            <h3>FULLSTACK</h3>
          </div>

          <ul className='service__list'>

            <li>
              <div> <BsFillFileEarmarkCheckFill className='service__list-icon'/> </div>
              <p>Websites from Scratch: Build complete websites with looks and functions.</p>
            </li>

            <li>
              <div> <BsFillFileEarmarkCheckFill className='service__list-icon'/> </div>
              <p>All Device Friendly: Design for computers, phones, and tablets.</p>
            </li>

            <li>
              <div> <BsFillFileEarmarkCheckFill className='service__list-icon'/> </div>
              <p>Linking Website Parts: Connect buttons and forms to make them work.</p>
            </li>

          </ul>
        </article>

      </div>
    </section>
  );
}

export default Services;
