import React from 'react'
import './portfolio.css'
import IMG1 from '../../assets/blog.jpg';

const project__data = [
  {
    id: 1,
    image: IMG1,
    title: 'Blog',
    live: 'https://blog.juantech.dev',
  },
]

const Portfolio = () => {
  return (
    <section id='portfolio'>
      <h5>My Recent Work</h5>
      <h2>Portfolio</h2>

      <div className="container portfolio__container">
        {
          project__data.map(({id, image, title, live}) => {
            return(
            <article key={id} className='portfolio__item'>
            <div className="portfolio__item-image">
              <img src={image} alt={title}/>
            </div>
            <div className='portfolio__title'>
              <h3>{title}</h3>
              <div className="portfolio__item-cta">
                <a href={live} className='btn btn-primary' target='_blank' rel="noreferrer">Live</a>
              </div>
            </div>
          </article>)
            
          })
        }
      </div>
    </section>
  )
}

export default Portfolio