import React from 'react'
import './testimonials.css'
import AVTR1 from '../../assets/avatar1.jpg'
import AVTR2 from '../../assets/avatar2.jpg'
import AVTR3 from '../../assets/avatar3.jpg'
import AVTR4 from '../../assets/avatar4.jpg'

// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/effect-flip";
import "swiper/css/pagination";
import "swiper/css/navigation";


// import required modules
import { EffectFlip, Pagination, Navigation } from "swiper";

const testimonials__data =[
 {
  avatar: AVTR1,
  name: 'First,Last',
  review: 'Lorem, ipsum dolor sit amet consectetur adipisicing elit. Accusantium in beatae, repudiandae, quod officiis rem totam atque soluta perspiciatis, autem a. Voluptate autem corporis veritatis, deleniti aut rem eos dolorem!',
 },
 {
  avatar: AVTR2,
  name: 'First,Last',
  review: 'Lorem, ipsum dolor sit amet consectetur adipisicing elit. Accusantium in beatae, repudiandae, quod officiis rem totam atque soluta perspiciatis, autem a. Voluptate autem corporis veritatis, deleniti aut rem eos dolorem!',
 },
 {
  avatar: AVTR3,
  name: 'First,Last',
  review: 'Lorem, ipsum dolor sit amet consectetur adipisicing elit. Accusantium in beatae, repudiandae, quod officiis rem totam atque soluta perspiciatis, autem a. Voluptate autem corporis veritatis, deleniti aut rem eos dolorem!',
 },
 {
  avatar: AVTR4,
  name: 'First,Last',
  review: 'Lorem, ipsum dolor sit amet consectetur adipisicing elit. Accusantium in beatae, repudiandae, quod officiis rem totam atque soluta perspiciatis, autem a. Voluptate autem corporis veritatis, deleniti aut rem eos dolorem!',
 },
]

const Testimonials = () => {
  return (
    
    <section id='testimonials'>
      <h5>Review from clients</h5>
      <h2>Testimonials</h2>
      
      <Swiper  effect={"flip"}
        grabCursor={true}
        pagination={true}
        navigation={true}
        modules={[EffectFlip, Pagination, Navigation]}
        className='container testimonials__container'>
        {
          testimonials__data.map(({avatar, name, review}, index) => {
            return (
              <SwiperSlide key={index} className='testimonial'>
              <div className="client__avatar">
                <img src={avatar} alt="Avatar One" />
              </div>
                <h5 className='client_name'>{name}</h5>
                <small className='client_review'>{review}        
                </small>
              </SwiperSlide>  
              )
            })
        } 
      </Swiper>
    </section>
  )
}

export default Testimonials