import React, { useRef, useState } from 'react'; // Importing useRef and useState
import './contact.css';
import { MdMarkEmailUnread } from 'react-icons/md';
import emailjs from 'emailjs-com';

const Contact = () => {
  const form = useRef();
  const [isFormSubmitted, setIsFormSubmitted] = useState(false); // State for tracking form submission

  const email = 'juancarbajalfullstack@gmail.com';

  const sendEmail = async (e) => {
    e.preventDefault();

    try {
      await emailjs.sendForm(
        'service_r4tlqwf',        
        'template_jnjebop',
        form.current,
        's3x7O4SYrQ7wBOi4F'
      );
      e.target.reset();
      setIsFormSubmitted(true); // Update state to indicate form submission
    } catch (error) {
      console.log(error.text);
    }
  };

  return (
    <section id='contact'>
      <h5>Get In Touch</h5>
      <h2>Contact</h2>

      <div className='container contact__container'>
        <div className='contact__options'>
          <article className='contact__option'>
            <MdMarkEmailUnread className='contact__option-icon'/>
            <h4>Email</h4>
            <h5 id='email'>{email}</h5>
            <a href={`mailto:${email}`} target='_blank' rel="noreferrer">Send a message</a>
          </article>
        </div>

        {isFormSubmitted ? (
          <p className="success-message">I'll get back to you as soon as possible.</p>
        ) : (
          <form ref={form} onSubmit={sendEmail}>
            <input type="text" name="name" placeholder='Your Full Name' required />
            <input type="email" name='email' placeholder='Your Email' required />
            <textarea name="message" rows="10" placeholder='Your Message' required></textarea>
            <button type='submit' className='btn btn-primary'>Send Message</button>
          </form>
        )}
      </div>
    </section>
  );
};

export default Contact;
