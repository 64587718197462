import React from 'react';
import './about.css';
import ME from '../../assets/me-about.jpg';
import { RiLightbulbFlashLine, RiContactsFill, RiFolderAddFill } from 'react-icons/ri';

const About = () => {
  return (
    <section id='about'>
      <h5>Get To Know</h5>
      <h2>About Me</h2>
      
      <div className="container about__container">
        <div className="about__me">
          <div className='about__me-image'>
            <img src={ME} alt="About" />
          </div>
        </div>

        <div className="about__content">
          <div className="about__cards">
            
            <article className="about__card">
              <RiLightbulbFlashLine className='about__icon' />
              <h5>Experience</h5>
              <small>1+ year</small>
            </article>

            <article className="about__card">
              <RiContactsFill className='about__icon' />
              <h5>Clients</h5>
              <small>Working...</small>
            </article>

            <article className="about__card">
              <RiFolderAddFill className='about__icon' />
              <h5>Projects</h5>
              <small>1+ Project</small>
            </article>

          </div>

          <div className='about__content'>
            <p>
              Hi there,
              <br />
              I'm <span id='name'>Juan Carbajal</span>, a self-taught web developer who loves making cool websites. I want to help you make a website that brings in new clients.
            </p>

            <h3>I can:</h3>

            <ul>
              <li>Make your website look cool and work well.</li>
              <li>Make a website that fits what you want.</li>
              <li>Make it work on computers and phones.</li>
              <li>Make sure it works fast and smooth.</li>
            </ul>

            <a href="#contact" className='btn btn-primary'>Let's Talk</a>
          </div>

        </div>
      </div>
    </section>
  );
}

export default About;
