import React from 'react';

import { About, Contact, Experience, Footer, Header, Navbar, Portfolio, Services, /* Testimonials */ } from './components'



const App = () => {
  return (
    <>
      <Header />
      <Navbar />
      <About />
      <Experience />
      <Services />
      <Portfolio />
      {/* <Testimonials /> */}
      <Contact />
      <Footer />
    </>
  )
}

export default App;